type ClassPropItem = string | string[] | Record<string, boolean>

export type ClassProp = ClassPropItem | ClassPropItem[]

export type ClassPropOrGetter<Context> =
  | ClassProp
  | ((context: Context) => ClassProp)

export const unwrapClassPropGetter = <Context>(
  classPropOrGetter: ClassPropOrGetter<Context>,
  context: Context
) => {
  if (typeof classPropOrGetter === 'function') {
    return classPropOrGetter(context)
  }

  return classPropOrGetter
}
